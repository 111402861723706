// pages/blog.js
import React from "react"
import { graphql,Link　} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ArticlePost from "../templates/blog-template"

const BlogPage = ({ data }) => (
 <Layout>
   <SEO title="ブログ" />
    <div className="content-box clearfix">

    {data.allMicrocmsBlog.edges.map(edge => {
      const blog = edge.node

        return (
          <React.Fragment key={blog.id}>
                <article className="post">
                <Link to={`/${blog.category[0].toLowerCase()}/${blog.id}`}>
                <div className="post-content">
                <h2 className="post-title">
                      {blog.title}
                </h2>
                    <p>{blog.category}</p>
                      <br />
                      {/* <img
                        src={blog.pict.url}
                        width={160}
                        height={110}
                        alt="pict画像"
                      /> */}
                      <p maxlength={100}
                        dangerouslySetInnerHTML={{
                          __html: `${blog.body}`,
                        }}
                      ></p>
                      <span className="post-date">
                          {blog.createdAt}
                      </span>
                    </div>
                    </Link>
                </article>
        </React.Fragment>

        )
    })}
  </div>
 </Layout>
)

export const query = graphql`
 {
    allMicrocmsBlog(
     sort: { fields: [id], order: ASC }
   ) {
     edges {
       node {
         id
         title
         body
         category
         createdAt(formatString: "YYYY年MM月DD日")
       }
     }
   }
 }
`

export default BlogPage