//templates/article.js
import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"

const ArticlePost = props => {
 const post = props.data.microcmsBlog
 return (
   <Layout>
     <div className="clearfix post-content-box">
        <article className="article-page">
          <div className="page-content">
            {/* <div className="page-cover-image">
                  <figure>
                    <Img
                      className="page-image"
                      key={post.frontmatter.img.childImageSharp.fluid.src}
                      fluid={post.frontmatter.img.childImageSharp.fluid}
                    />
                  </figure>
              </div> */}
            <div className="wrap-content">
              <header className="header-page">
                  <h1 className="page-title">{post.title}</h1>
                  <div className="page-date">
                    <span className="post-date">
                          {post.createdAt}
                      </span>
                  </div>
             </header>
              <div className="page-body"
              dangerouslySetInnerHTML={{
                __html: `${post.body}`,
              }}
              />

                <div className="page-footer">
                    <div className="page-tag">
                      <span>{post.category}</span>
                    </div>
                </div>
              </div>

            
          </div>
        </article>
      </div>

   </Layout>
 )
}

export default ArticlePost

export const query = graphql`
 query($id: String!) {
   microcmsBlog(id: { eq: $id }) {
     title
     body
     category
     createdAt(formatString: "YYYY年MM月DD日")
   }
 }
`